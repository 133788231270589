#coming-soon {
    display: flex;
    align-items: center;
    padding-top: 180px;
    padding-bottom: 100px;
    flex-direction: column;
    width: 100vw;
}

.cs-text {
font-size: 3.5rem;
color: chocolate;
font-style: italic;
}

.cs-watch-text {
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0.76);
}