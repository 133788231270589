#not-found {
    width: 100vw;
    padding-top: 180px;
    padding-bottom: 100px;
    font-size: 130px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 400px) {
    #not-found {
        padding-top: 40px;
    }
}