#nav-bar {
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  box-shadow: 1px 1px 5px #ccc;
  top: 0px;
  min-width: 100%;
  z-index: 5;
  position: fixed;
}

.left-section {
  display: flex;
  align-items: center;
}

.right-section {
  align-items: center;
  margin-left: 48px;
}

.about-us {
  margin-left: 16px;
  margin-right: 16px;
}

.contact {
  margin-right: 16px;
}

.get-started {
  margin-right: 48px;
}

#logo {
  color: rgb(0 74 173);
  font-family: "Cabin", sans-serif;
  margin: 14px 0px 16px 0px;
  letter-spacing: 1px;
  position: relative;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: black;
}

.nav-links {
  border-bottom: 3px solid transparent;
  padding-bottom: 22px;
  transition: all 0.2s;
}

.nav-links:hover {
  border-bottom: 3px solid rgb(0 74 173);
}

.header-atag {
  color: #198754;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #198754;
}

.header-atag:hover {
  background-color: #198754;
  color: white;
}

#menu-bar {
  display: none;
}

#menu-item {
  display: none;
}

@media screen and (max-width: 400px) {
  .left-section,
  .about-us,
  .contact {
    display: none;
  }

  #menu-bar {
    display: inline-block;
    position: relative;
    right: 30px;
    top: 20px;
  }

  #menu-item {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: white;
    top: 100%;
    right: 0;
    padding: 20px 36px;
    transition: all 0.3s;
    opacity: 0.9;
  }

  .active-menu {
    transform: translateX(150px);
  }

  .no-active-menu {
    transform: translateX(0px);
  }

  .first-menu {
    margin-top: 100px;
  }

  .nav-links {
    margin-top: 15px;
    display: inline-block;
    padding-bottom: 5px;
  }
}
