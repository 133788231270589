#edu-main {
  display: flex;
  justify-content: space-around;
  margin-top: 17%;
  margin-bottom: 11%;
}

.edu-details {
  width: 500px;
  text-align: left;
}

.edu-content {
  line-height: 30px;
  margin-top: 32px;
  font-size: 19px;
  opacity: 0.8;
}

@media screen and (max-width: 400px) {
  #edu-main {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .edu-details {
    width: 300px;
    text-align: center;
  }

  #edu-main {
    padding-top: 80px;
  }
}
