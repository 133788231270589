#consulting {
    padding-top: 9%;
    padding-bottom: 9%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.cons-details {
    max-width: 500px;
}

.cons-heading {
    color: rgba(0, 0, 0, 0.8);
}

.cons-content {
    margin-top: 7%;
    color: rgba(0, 0, 0, 0.6);
    line-height: 29px;
    font-size: 19px;
}

@media screen and (max-width: 400px) {
    #consulting {
        flex-direction: column;
        align-items: center;
        margin-top: 0px;
    }   

    .cons-image {
        width: 200px;
        height: 200px;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .cons-details {
        padding: 80px 16px 12px 16px;
        width: 100%;
    }
}
