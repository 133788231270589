body {
    background: white; 
}

#card-section {
    display: flex;
    justify-content: center;
    margin: 150px;
    text-align: left;
}

.first-card {
    width: 18rem;
    border-radius: 6px;
    transition: transform 0.3s ease-in-out ;
}

.first-card:hover {
    transform: scale(1.01);
    box-shadow: 0px 0px 5px  #ccc;
}



.edu-img {
    height: 172.14px;
    border-radius: 6px 6px 0 0;
}

.middle-card {
    margin-left: 50px;
    margin-right: 50px;
}

img {
    border-radius: 6px 0 0 0;
}

.list-item {
    list-style-type: disc;
    text-align: left;
}

.card-button {
    margin-left: 30%;
}

@media screen and (max-width: 400px) {
    #card-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 85px;
    }

    .first-card {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}