#contact-main {
    display: flex;
    justify-content: space-around;
    margin-top: 15%;
    margin-bottom: 10%;
}

@media screen and (max-width: 400px) {
    #contact-main {
        flex-direction: column;
        padding-top: 40px;
        padding-left: 50px;

    }
}
